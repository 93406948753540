@import url(https://fonts.googleapis.com/css?family=Lato&display=swap&subset=latin-ext);
* {
	 margin: 0;
	 padding: 0;
}
 body {
	 background-color: #f7f7f7;
	 font-family: 'Lato', sans-serif;
}
 .book-wrap {
	 position: relative;
	 -webkit-transform: scale(0.8);
	         transform: scale(0.8);
	 transition: all 2s ease !important;
	 width: 800px;
	 margin: auto;
}
 .book-wrap.closed {
	 -webkit-transform: rotateY(-180deg) !important;
	         transform: rotateY(-180deg) !important;
	 -webkit-transform-style: preserve-3d !important;
	         transform-style: preserve-3d !important;
}
 .book-wrap.scaled {
	 -webkit-transform: scale(1);
	         transform: scale(1);
	 -webkit-transform: translateX(200px);
	         transform: translateX(200px);
}
 @-webkit-keyframes dude {
	 100% {
		 -webkit-transform: scale(1);
		         transform: scale(1);
	}
}
 @keyframes dude {
	 100% {
		 -webkit-transform: scale(1);
		         transform: scale(1);
	}
}
 .book-bg {
	 -webkit-filter: drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.2));
	         filter: drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.2));
	 position: relative;
	 width: 400px;
	 height: 400px;
	 margin: 100px auto;
	 background-size: cover;
	 -webkit-transform-style: preserve-3d;
	         transform-style: preserve-3d;
	 -webkit-perspective: 1000px;
	         perspective: 1000px;
	 position: relative;
	 background: radial-gradient(circle at bottom center, transparent, transparent 50px, #333 20px);
}
 .pull {
	 -webkit-filter: drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.2));
	         filter: drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.2));
	 background: #424242;
	 position: absolute;
	 bottom: -90px;
	 width: 80px;
	 height: 200px;
	 left: calc(50% - 40px);
	 -webkit-transform: translateY(-50%);
	         transform: translateY(-50%);
	 border-radius: 50px;
	 transition: bottom 2s ease;
	 cursor: pointer;
}
 .pull.pull.down {
	 bottom: -160px;
}
 .pull .submit-btn {
	 color: white;
	 position: absolute;
	 bottom: 32px;
	 display: block;
	 width: 100%;
	 text-align: center;
}
 .book-cover {
	 position: absolute;
	 width: 100%;
	 height: 100%;
	 background: white;
	 background-size: cover;
	 -webkit-transform-origin: 0 50%;
	         transform-origin: 0 50%;
	 transition: -webkit-transform 2s ease;
	 transition: transform 2s ease;
	 transition: transform 2s ease, -webkit-transform 2s ease;
	 -webkit-transform: rotateY(0deg);
	         transform: rotateY(0deg);
}
 .book-bg .book-cover.hover {
	 -webkit-transform: rotateY(-180deg);
	         transform: rotateY(-180deg);
}
 .code-wrap {
	 position: absolute;
	 bottom: 80px;
	 left: 50%;
	 margin-left: -100px;
	 width: 200px;
	 background: #424242;
	 height: 42px;
	 border-radius: 21px;
	 overflow: hidden;
	 box-shadow: inset -1px 0px 2px 0px rgba(0, 0, 0, 0.75);
}
 .code-wrap.code-wrap-1, .code-wrap.code-wrap-2, .code-wrap.code-wrap-3, .code-wrap.code-wrap-4 {
	 width: 340px;
	 margin-left: -170px;
}
 .code-wrap.code-wrap-4 {
	 bottom: 140px;
}
 .code-wrap.code-wrap-3 {
	 bottom: 200px;
}
 .code-wrap.code-wrap-2 {
	 bottom: 260px;
}
 .code-wrap.code-wrap-1 {
	 bottom: 320px;
}
 .code-wrap input {
	 position: absolute;
	 top: -70px;
	 transition: top 2s ease;
	 background: none;
	 border: none;
	 height: 100%;
	 width: 100%;
	 text-align: center;
	 font-size: 20px;
	 outline: none;
	 color: white;
}
 .code-wrap input::-ms-clear {
	 display: none;
}
 .code-wrap input:disabled {
	 opacity: 0.5;
}
 .code-wrap.down input {
	 top: 0px;
}
 .left-page {
	 margin: 0;
	 position: absolute;
	 top: 50%;
	 left: 50%;
	 -webkit-transform: translate(-50%, -50%) scaleX(-1);
	         transform: translate(-50%, -50%) scaleX(-1);
	 white-space: nowrap;
}
 .left-page p {
	 margin: 16px 0;
}
 .back-page {
	 position: absolute;
	 top: 50%;
	 left: 50%;
	 -webkit-transform: translate(-50%, -50%);
	         transform: translate(-50%, -50%);
	 width: 80%;
	 text-align: center;
}
 .bg {
	 width: 400px;
	 height: 400px;
	 background: white;
	 position: absolute;
	 z-index: 2;
	 -webkit-transform: scaleX(-1);
	         transform: scaleX(-1);
}
 .invisible-btn {
	 position: absolute;
	 bottom: 0;
	 left: 50%;
	 width: 100px;
	 margin-left: -50px;
	 height: 114px;
	 bottom: -63px;
	 opacity: 0;
	 cursor: pointer;
}
 .rc-dialog {
	 border-radius: 2px;
	 max-width: 360px;
}
 @media only screen and (max-width: 440px) {
	 html {
		 background-color: #333;
	}
}
 .easy-form {
	 -webkit-filter: drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.2));
	         filter: drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.2));
	 background-color: #333;
	 padding: 20px;
	 max-width: 400px;
	 margin: 20px auto 0;
	 color: white;
}
 @media only screen and (max-width: 440px) {
	 .easy-form {
		 margin-top: 0;
		 -webkit-filter: none;
		         filter: none;
	}
}
 .easy-form .logo {
	 max-width: 300px;
	 margin: 0 auto;
	 margin-bottom: 20px;
	 width: 100%;
}
 .easy-form input {
	 display: block;
	 background: none;
	 border: none;
	 height: 100%;
	 width: calc(100% - 20px);
	 font-size: 20px;
	 outline: none;
	 color: white;
	 border-radius: 3px;
	 background: #424242;
	 box-shadow: inset -1px 0px 2px 0px rgba(0, 0, 0, 0.75);
	 padding: 4px 10px;
}
 .easy-form input + input {
	 margin-top: 10px;
}
 .easy-form input.code {
	 max-width: 100px;
}
 .easy-form input:disabled {
	 opacity: 0.5;
}
 .easy-form button {
	 color: white;
	 background-color: darkgreen;
	 border: none;
	 font-size: 18px;
	 padding: 6px 10px;
	 margin-top: 10px;
	 border-radius: 3px;
}
 .rc-dialog {
	 border-radius: 3px;
}
 .rc-dialog button {
	 font-size: 16px;
	 color: white;
	 background-color: #424242;
	 border: none;
	 padding: 6px 10px;
	 margin-top: 10px;
	 border-radius: 3px;
}
 .rc-dialog-close {
	 display: none;
}
 .rc-dialog-content {
	 border-radius: 0 !important;
}
 
